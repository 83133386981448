@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Bannerfamily component */ 
.SL_Bannerfamily {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  margin-bottom: 64px;
  margin-top: -80px;
  padding-top: 150px;
  background-color: colors.$color-charcoal;
  z-index: -1;

  @include mq.media(">=desktop") {
    padding-top: 170px;
    padding-bottom: 32px;
    margin-bottom: 112px;
  }
}

.SL_Bannerfamily-title {
  margin-bottom: 32px;
  text-align: center;
  color: colors.$color-white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;

  @include mq.media(">=desktop") {
    font-size: 3.4rem;
    margin-bottom: 80px;
  }
}

.SL_Bannerfamily-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @include mq.media(">=desktop") {
    justify-content: center;
    width: 100%;
  }
}

.SL_Bannerfamily-productContainer {
  width: 42%;
  
  @include mq.media(">=desktop") {
    flex: 0 0 20%;
    padding: 10px;
  }
}

.SL_Bannerfamily-productIcon {
  width: fit-content;
  padding: 24px;
  margin: 0 auto;
  background-color: colors.$color-white;
  border-radius: 100px;
  
  .icon {
    width: 33px;
    height: 33px;
  }
}

.SL_Bannerfamily-productTitle {
  display: block;
  margin-bottom: 8px;
  margin-top: 16px;
  text-align: center;
  color: colors.$color-white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;

  @include mq.media(">=desktop") {
    font-size: 1.8rem;
    line-height: 25px;
  }
}

.SL_Bannerfamily-label {
  display: block;
  padding: 8px 16px;
  margin-bottom: 8px;
  text-align: center;
  color: colors.$color-charcoal;
  background-color: colors.$color-white;
  border: 1px solid colors.$color-gray-2;
  border-radius: 100px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  
  @include mq.media(">=desktop") {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.SL_Bannerfamily-label:last-child {
  margin-bottom: 24px;

  @include mq.media(">=desktop") {
    margin-bottom: 0;
  }
}