@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Card component */ 
.SL_Card {
  display: flex;
  margin: 24px 0;
  padding: 16px;
  border: 1px solid colors.$color-red;
  border-radius: 4px;
  
  @include mq.media(">=tablet") {
    width: 600px;
    padding: 24px;
    margin: 24px 0;
  }
}

.SL_Card-icon {
  margin-right: 16px;
  text-align: center;
  color: colors.$color-red;
  
  .icon {
    min-width: 48px;
    min-height: 48px;
    fill: colors.$color-red;
  }
}

.SL_Card-content {
  @include mq.media(">=tablet") {
    width: 100%;
  }
}

.SL_Card-position {
  @include mq.media(">=tablet") {
    display: flex;
    justify-content: space-between;
  }
}

.SL_Card-position > div > *{
  display: block;
  font-family: 'Lato';
  font-style: normal;
}

.SL_Card-position > div > span {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;

  @include mq.media(">=tablet") {
    font-size: 1.8rem;
    line-height: 25px;
  }
}

.SL_Card-adresse {
  text-transform: lowercase;
}

.SL_Card-ville {
  text-transform: capitalize;
}

@include mq.media(">=tablet") {
  .SL_Card-map-title {
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 22px;
    white-space: nowrap;

    .icon {
      fill: colors.$color-red;
      vertical-align: middle;
      width: 28px;
      height: 28px;
    }
  }
}

.SL_Card-title {
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 22px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }

  @include mq.media(">=tablet") {
    font-size: 2.4rem;
    line-height: 29px;
  }
}

.SL_Card-map {
  display: none;

  @include mq.media(">=desktop") {
    display: block;
  }
}

.SL_Card-certifications {
  display: none;

  @include mq.media(">=tablet") {
    display: flex;
    margin-top: 16px;
  }
}

.SL_Card-cretifications-icon {
  height: 32px;
  margin-right: 16px;
}

.SL_Card-hoursContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.SL_Card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  text-align: center;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
  
  @include mq.media(">=tablet") {
    font-size: 1.8rem;
    line-height: 22px;
  }
  
  @include mq.media("<tablet") {
    width: 222px;
    margin-left: -8px;
  }
  
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: text-top;
    
    @include mq.media(">=tablet") {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
    }
  }
}

.SL_Card-buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  
  @include mq.media(">=tablet") {
    flex-direction: row;
  }
}


.SL_Card-button--devis {
  position: relative;
  color: colors.$color-white;
  background-color: colors.$color-red;
  border: 1px solid colors.$color-red;
  
  .icon {
    fill: colors.$color-white;
  }
  
  .icon-SLminus {
    display: none;
  }
}

.SL_Card-button--devis:hover {
  color: colors.$color-white;
}

.SL_Card-error {
  position: absolute;
  top: -34px;
  left: 50%;
  padding: 4px 10px;
  white-space: nowrap;
  color: colors.$color-white;
  background-color: colors.$color-dark-gray;
  border-radius: 50px;
  transform: translateX(-50%);
}

.SL_Card-button--num:hover {
  color: colors.$color-red;
}

.SL_Card-button--num {
  color: colors.$color-red;
  border: 1px solid colors.$color-red;
  
  @include mq.media("<=tablet") {
    margin-top: 8px;
  }
  
  @include mq.media(">tablet") {
    margin-left: 8px;
  }
  
  .icon {
    fill: colors.$color-red;
  }
}

.SL_Card-buttonContainer--secondary {
  width: 220px;

  .SL_Card-button--num {
    margin-left: 0;
  }
}

.SL_Card-button--removeDevis {
  color: colors.$color-gray-2;
  background-color: colors.$color-SLgrey;
  border-radius: 100px;
  border-color: colors.$color-SLgrey;
  
  &:hover {
    color: colors.$color-gray-2;
  }

  .icon-SLminus {
    display: inherit;
  }
  
  .icon-SLplus {
    display: none;
  }
}

.SL_Card-button--template {
  margin-top: 8px;
  margin-left: 0;

  @media screen and (max-width: 500px) {
    width: 150px;
    padding: 8px 5px;
    font-size: 1.3rem;
  }
}