@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Territorysearchform component */ 
.SL_Territorysearchform {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;

  @include mq.media("<desktop") {
    flex-direction: column;
    margin-right: 20px;
    width: auto;
  }

  .SL_Territorysearchform-select {
    width: 100%;
    margin-bottom: 8px;
    padding: 20px 14px 20px 14px;
    color: colors.$color-dark-gray;
    background-color: colors.$color-gray-4;
    border: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 19px;
    
    
    @include mq.media(">=desktop") {
      height: 60px;
      width: 33%;
    }
    
    option {
      display: block;
      color: colors.$color-dark-gray;
      background-color: colors.$color-white;
    }
  }
  
  .SL_Searchform-placeholder {
    color: colors.$color-gray-2;
  }
  
  select:active {
    
    option {
      background-color: colors.$color-white;
    }
  }
}

.SL_Territorysearchform-button {
  width: 100%;
  padding: 10px 0px 10px 20px;
  color: colors.$color-dark-gray;
  font-family: 'Lato';
  font-style: normal;
  font-size: 1.6rem;
  line-height: 22px;
  
  @include mq.media(">=desktop") {
    width: max-content;
  }

  @include mq.media("<desktop") {
    text-align: center;
  }
}

.SL_Territorysearchform-button:hover {
  text-decoration: underline;
}