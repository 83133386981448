@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Selectedaccordion component */ 
.SL_Selectedaccordion {
  position: absolute;
  bottom: -72px;
  width: 100%;
  background-color: colors.$color-red;
  border-radius: 12px;
  box-shadow: 0px -5px 25px rgb(0 0 0 / 10%);
}

.SL_Selectedaccordion-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 64px;
  color: colors.$color-white;
}

.SL_Selectedaccordion-title {
  font-weight: 900;
  font-size: 1.6rem;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}

.SL_Selectedaccordion-infosContainer {
  color: colors.$color-white;
  font-size: 1.6rem;

  svg {
    width: 28px;
    height: 28px;
    transform: rotate(-90deg);
  }
}

.SL_Selectedaccordion-details {
  padding: 8px;
  background-color: colors.$color-white;
  border-radius: 0 0 10px 10px;
  
  @include mq.media(">=desktop") {
    padding: 16px;
  }
}

.SL_Selectedaccordion-detailsContainer {
  display: flex;
  flex-wrap: wrap;

  .SL_Selectedaccordion-company {
    position: relative;
    padding: 8px 40px 8px 16px;
    margin-bottom: 4px;
    color: colors.$color-dark-gray;
    border: 1px solid colors.$color-gray-2;
    border-radius: 100px;
    font-size: 1.6rem;
    
    &:not(:last-child) {
      margin-right: 4px;
    }
    
    button {
      position: absolute;
      display: flex;
      right: 6px;
      top: 4px;
      padding: 8px;
    }

    .icon-SLcross {
      width: 16px;
      height: 16px;
    }
  }
}

.SL_Selectedaccordion-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 44px;
  color: colors.$color-dark-gray;
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 22px;

  @include mq.media(">=desktop") {
    font-size: 1.8rem;
  }

  span {
    padding: 10px;
    margin-left: 8px;
    color: colors.$color-white;
    background-color: colors.$color-red;
    border: 1px solid colors.$color-white;
    border-radius: 100px;

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
    }
  }
}

.SL_Selectedaccordion-listMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 10px 10px 0 0;
  z-index: 10;
}