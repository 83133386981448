@use 'utilities/colors';

/*! SL_Selectdisplay component */ 
.SL_Selectdisplay {
  display: flex;
  width: 100%;
  max-width: 600px;
}

.SL_Selectdisplay-option {
  width: 50%;
  padding: 8px 0;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
}

.SL_Selectdisplay-option:first-child {
  color: colors.$color-white;
  background-color: colors.$color-dark-gray;
  border-radius: 100px 0px 0px 100px;
}

.SL_Selectdisplay-option:last-child {
  color: colors.$color-dark-gray;
  background-color: colors.$color-white;
  border-radius: 0px 100px 100px 0px;
  box-shadow: inset 0px 0px 0px 1px colors.$color-dark-gray;
}