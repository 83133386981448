@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_ResultCard component */ 
.SL_ResultCard {
  background-color: colors.$color-SLgrey;
  padding: 24px 32px;
  margin-bottom: 20px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq.media(">=desktop") {
    max-width: 588px;
    padding: 64px;
  }
}

.SL_ResultCard-title {
  margin-bottom: 24px;
  color: colors.$color-dark-gray;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 32px;
}

.SL_ResultCard-button {
  width: 100%;
  padding: 19px 30px;
  text-align: center;
  color: colors.$color-dark-gray;
  border: 3px solid colors.$color-dark-gray;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 22px;
  
  @include mq.media(">=tablet") {
    max-width: 300px;
  }
}

.SL_ResultCard--secondary {
  background-color: colors.$color-white;
  border: 1px solid colors.$color-gray-2;
  border-radius: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-size: 1.8rem;
  line-height: 25px;

  .SL_ResultCard-richText {
    margin-bottom: 32px;
  }
  
  .SL_ResultCard-richText .ezrichtext-field > p {
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  @include mq.media(">=tablet") {
    max-width: 80%;
    padding: 64px 173px;
    margin: 0 auto 140px;
    font-family: 'Lato';
    font-style: normal;
    font-size: 2.4rem;
    line-height: 32px;

    .SL_ResultCard-richText .ezrichtext-field > p {
      font-weight: 300;
    }

    .SL_ResultCard-richText {
      margin-bottom: 64px;
    }
  }
}