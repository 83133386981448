/*! SL_Map component */ 
@use 'utilities/mq';
@use 'utilities/colors';

.SL_Map {

  .SL_Map-content {
    height: 600px;
    
    @include mq.media(">=desktop") {
      height: 760px;
    }
  
    @media screen and (max-height: 900px) {
      height: 600px;
    }
  }
  
  .SL_Map-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;
    padding-left: 10px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
  
    .SL_Map-bubbleTitle {
      text-align: center;
      font-weight: 900;
      font-size: 1.6rem;
      margin-bottom: 4px;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    .SL_Map-bubbleIconContainer {
      margin-top: 16px;
    }
  
    .SL_Map-bubbleImage {
      width: 30px;
  
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  
    .SL_Map-bubbleButton {
      margin-top: 12px;
      padding: 8px 12px;
      color: colors.$color-red;
      border: 1px solid colors.$color-red;
      font-weight: 900;
      font-size: 1.6rem;
      line-height: 19px;
    }
  }
}
