@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Bannerqualif component */ 
.SL_Bannerqualif {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 300px;
  margin-top: -80px;
  margin-bottom: 64px;
  background-color: colors.$color-charcoal;
  z-index: -1;

  @include mq.media(">=desktop") {
    height: 400px;
    margin-bottom: 112px;
  }
}

.SL_Bannerqualif-title {
  position: absolute;
  top: 128px;
  width: 100%;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;
  color: colors.$color-white;

  @include mq.media(">=desktop") {
    top: 176px;
  }
}

.SL_Bannerqualif-container {
  display: flex;
  flex-wrap: wrap;
  margin: 200px 15px 0;
  background: colors.$color-white;
  
  @include mq.media(">=desktop") {
    flex-wrap: nowrap;
    margin: 0;
  }
}

.SL_Bannerqualif-imageContainer {
  width: 50%;
  padding: 5px 0;
  text-align: center;
  
  @include mq.media(">=desktop") {
    width: 150px;
    padding: 32px 0;
  }
}

.SL_Bannerqualif-image {
  height: 64px;
}

.SL_Bannerqualif--secondary {
  height: 300px;
}

.SL_Bannerqualif-imageContainer--secondary {
  width: 100%;

  @include mq.media(">=desktop") {
    width: 150px;
  }
}