@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Blockpromo component */ 
.SL_Blockpromo {
  margin-bottom: 64px;
  color: colors.$color-dark-gray;
  
  @include mq.media(">=desktop") {
    margin-bottom: 136px;
  }

  p {
    font-size: 1.2rem;
    line-height: 16px;
  }
}

.SL_Blockpromo-title {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;

  @include mq.media(">=desktop") {
    margin-bottom: 48px;
  }
}

.SL_Blockpromo-slideContainer {
  position: relative;
  margin-bottom: 2.5rem;
  height: auto;
  overflow: hidden;
  
  @include mq.media(">=desktop") {
    height: 45rem;
  }

  &__item {
    height: 100%;
    position: relative;
    margin-bottom: 0;
    overflow: hidden;

    picture,
    img {
      max-width: 100%;
      min-width: 100%;
      height: inherit;
    }
  }

  .slick-dots {
    bottom: 2px;

    @include mq.media(">=desktop") {
      bottom: 15px;

      li.slick-active button{
      
        &::before {
          color: #FFFFFF;
        }
      }
    }
  }
}

.SL_Blockpromo-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}