@use 'utilities/mq';

/*! SL_Workedproduct component */ 
.SL_Workedproduct {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 64px;

  & > .SL_Workedproduct {
    display: block;
    text-align: center;
    margin-bottom: 64px;

    @include mq.media(">=desktop") {
      margin-bottom: 112px;
    }
  }
}

.SL_Workedproduct-title {
  display: block;
  width: 100%;
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;
  
  @include mq.media(">=desktop") {
    margin-bottom: 48px;
    font-size: 3.4rem;
    line-height: 41px;
  }
}

.SL_Workedproduct-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0px 20px 24px;
  text-align: center;
  width: 30%;

  @include mq.media(">=desktop") {
    width: auto;
    flex: 0 0 15%;
  }
}

.SL_Workedproduct-iconContainer {
  padding: 23px;
  margin-bottom: 16px;
  box-shadow: 0px 6px 13px rgb(53 10 6 / 25%);
  border-radius: 50%;

  svg {
    width: 32px;
    height: 32px;
  }
}

.SL_Workedproduct-description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;

  @include mq.media(">=desktop") {
    font-size: 1.8rem;
    line-height: 25px;
  }
}