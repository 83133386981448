@use 'utilities/mq';
@use 'utilities/colors';

.SL_Formquote {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  margin-bottom: 40px;
  background: linear-gradient(
    to bottom,
    colors.$color-SLgrey 675px,
    colors.$color-white 675px 100%
  );
  border: 1px solid colors.$color-gray-2;
  border-radius: 5px;

  @include mq.media(">=tablet") {
    padding: 64px;
    background: linear-gradient(
      to bottom,
      colors.$color-SLgrey 590px,
      colors.$color-white 590px 100%
    );
  }

  .form-group {
    width: 100%;
    margin-bottom: 16px;
    
    & > label {
      position: relative;
      color: colors.$color-dark-gray;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 1.6rem;
      line-height: 19px;

      .invalid-feedback {
        position: absolute;
        width: max-content;
        top: 70px;

        @media screen and (max-width: 350px) {
          max-width: 275px;
        }
      }
    }

    & > button {
      width: 100%;
      padding: 19px 0;
      margin-top: 16px;
      text-align: center;
      color: colors.$color-dark-gray;
      background: none;
      border: 3px solid colors.$color-dark-gray;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 22px;

      @include mq.media(">=tablet") {
        max-width: 500px;
      }
    }

    & > textarea {
      min-height: 160px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 19px;
    }

    & > select, input {
      height: auto;
      padding: 13px 14px;
      color: colors.$color-gray-2;
      border: 2px solid colors.$color-border;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 19px;
    }
  }

  .g-recaptcha {
    width: 100%;
    margin: 15px;
  }

  .required::after {
    content: ' *';
    color: colors.$color-red;
  }
}

.SL_Formquote-titleContainer {
  margin-bottom: 24px;
  text-align: center;

  @include mq.media(">=tablet") {
    margin-bottom: 32px;
  }
}

.SL_Formquote-titleContainer--secondary {
  margin-top: 48px;
  
  @include mq.media(">=tablet") {
    margin-top: 112px;    
  }

  .SL_Formquote-title {
    margin-bottom: 4px;
    color: colors.$color-dark-gray;
  }
}

.SL_Formquote-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 22px;

  @include mq.media(">=tablet") {
    font-size: 2.4rem;
    line-height: 29px;
  }
}

.SL_Formquote-inputContainer {
  width: 100%;

  @include mq.media(">=tablet") {
    display: flex;

    & .form-group:not(:last-child) {
      margin-right: 24px;
    }
  }
  
}

.SL_Formquote-checkbox {
  margin-top: 16px;
  
  @include mq.media(">=tablet") {
    width: 100%;
  }
}

.SL_Formquote-checkbox span {
  display: block;
  margin-bottom: 8px;
  color: colors.$color-dark-gray;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
  
  @include mq.media(">=tablet") {
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}

.SL_Formquote-checkbox > .form-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @include mq.media(">=tablet") {
    justify-content: flex-end;
  }
}

.SL_Formquote-checkbox > .form-group > label {
  margin-left: 8px;
  margin-bottom: 0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
}

.SL_Formquote-companies {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  
  @include mq.media(">=tablet") {
    flex-direction: row;
    justify-content: space-between;
  }

  .form-group {    
    @include mq.media(">=tablet") {
      width: 40%;
    }
  }
}

.SL_Formquote-companiesContainer {
  width: 100%;
  padding: 24px;
  background-color: colors.$color-SLgrey;
  
  @include mq.media(">=tablet") {
    width: 50%;
  }
}

.SL_Formquote-companies-title {
  display: block;
  margin-bottom: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;
  color: colors.$color-dark-gray;
}

.SL_Formquote-companies-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.SL_Formquote-companies-item {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
  
  &:not(:last-child) {
    margin-right: 4px;
  }

  .SL_Formquote-company {
    position: relative;
    padding: 8px 40px 8px 16px;
    margin-bottom: 4px;
    color: colors.$color-dark-gray;
    border: 1px solid colors.$color-gray-2;
    border-radius: 100px;
    font-size: 1.6rem;

    button {
      position: absolute;
      display: flex;
      right: 7px;
      top: 2px;
      padding: 8px;
    }

    .icon-SLcross {
      width: 16px;
      height: 16px;
    }
  }
}

.SL_Formquote-requiredmessage {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 21px;

  @include mq.media(">=tablet") {
    left: 74%;
    transform: none;
  }
}

.SL_Formquote-requiredmessage::before {
  content: '* ';
  color: colors.$color-red;
}