@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Hero component */ 
.SL_Hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 340px;
  background-color: colors.$color-black;
  
  @include mq.media(">=tablet") {
    align-items: none;
    min-height: 450px;
  }
}

.SL_Hero-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.SL_Hero-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.SL_Hero-content {
  padding: 65px 24px;
  font-family: 'Lato';
  font-style: normal;
  color: colors.$color-white;
  z-index: 1;
  
  @include mq.media(">=desktop") {
    position: absolute;
    left: 50%;
    padding: 20px 120px 20px 110px;
  }
}

.SL_Hero-title {
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;
  
  @include mq.media(">=desktop") {
    margin-bottom: 16px;
    font-size: 3.4rem;
    line-height: 41px;
  }
}

.SL_Hero-subtitle {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 25px;
}

.SL_Hero--secondary {
  min-height: 265px;
  margin-bottom: -110px;
  z-index: -1;
  background-color: colors.$color-charcoal;

  @include mq.media(">=desktop") {
    margin-bottom: -80px;
  }

  .SL_Hero-content {
    position: absolute;
    top: 28%;
    left: 0;
    width: 100%;
    transform: none;
    text-align: center;
    padding: 0;

    @include mq.media(">=desktop") {
      top: 28%;
      left: 50%;
      transform: translateX(-50%);
    }
  }  

  .icon-SLchevron {
    width: 28px;
    height: 28px;
  }
}