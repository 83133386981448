/*! Breadcrumbs Component */
@use 'utilities/mq';

.Breadcrumbs {
  padding: 20px 30px;
  background-color: #eee;

  @media print {
    display: none;
  }

  @include mq.media("<tablet") {
    display: none;
  }

  ul {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    display: inline-block;
    &:not(:last-child)::after {
      content: ">";
      margin-left: 4px;
      margin-right: 3px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}
