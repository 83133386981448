@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_Mapinfo component */ 
.SL_Mapinfo {
  width: 100%;
  background-color: colors.$color-SLgrey;

  @include mq.media(">=desktop") {
    display: flex;
  }
}

.SL_Mapinfo-map {
  height: 327px;
  width: 100%;

  @include mq.media(">=desktop") {
    height: 100%;
    width: 50%;
  }
}

.SL_Mapinfo-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  @include mq.media(">=desktop") {
    width: 50%;
    padding: 64px;
  }
}

.SL_Mapinfo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid colors.$color-gray-2;

  @include mq.media(">=desktop") {
    align-items: flex-start;
  }

  & > span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 25px;

    @include mq.media(">=desktop") {
      font-weight: 300;
      font-size: 2.4rem;
      line-height: 32px;
    }
  }
}

.SL_Mapinfo-info-actionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq.media(">=desktop") {
    & > a {
      margin-right: 8px;
    }
  }
}

.SL_Mapinfo-info-adresse {
  text-transform: lowercase;
}

.SL_Mapinfo-info-ville {
  text-transform: capitalize;
}

.SL_Mapinfo-info-pays {
  margin-bottom: 16px;
}

.SL_Mapinfo-info-actionContainer > a:not(:first-child) {
  display: none;

  @include mq.media(">=desktop") {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid colors.$color-red;
    border-radius: 50%;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.SL_Mapinfo-info-actionMap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid colors.$color-red;
  color: colors.$color-red;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 22px;

  &:hover {
    color: colors.$color-red;
  }

  @include mq.media(">=desktop") {
    width: auto;    
  }

  .icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

.SL_Mapinfo-info-hours {
  width: 100%;
}

.SL_Mapinfo-info-hours-title {
  display: block;
  margin-bottom: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
}

.SL_Mapinfo-info-hours-details {
  display: block;
}

.SL_Mapinfo-info-location {
  width: 100%;
}

.SL_Mapinfo-info-location-title {
  display: block;
  margin-bottom: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
}

.SL_Mapinfo-info-location-details {
  text-transform: capitalize;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
}
