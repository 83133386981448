/*! Component1 Component */

@use "sass:math";
@use 'utilities/grid';
@use 'utilities/mq';

.GridExample {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: lightblue;

  &:first-of-type {
    .GridExample-wrapper {
      @include grid.wrapper($width: 1440px);
    }

    .GridExample-list {
      @include grid.grid($gutters: 10px);
    }

    .GridExample-item {
      @include grid.cell(math.div(12, 5), $gutters: 10px);
      height: 150px;
      background-color: lightcoral;
    }
  }

  &:last-of-type {
    .GridExample-wrapper {
      @include grid.wrapper();
    }

    .GridExample-list {
      @include mq.media(">=tablet") {
        @include grid.grid();
      }
    }

    .GridExample-item {
      height: 150px;
      background-color: lightcoral;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @include mq.media(">=tablet") {
        @include grid.cell(6, $gutter-type: "padding");
      }

      @include mq.media(">=desktop") {
        @include grid.cell(3);
      }
    }
  }
}
