@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_sectionhead component */ 
.SL_Sectionhead {
  position: relative;
  width: 100%;
  margin-bottom: 80px;
  padding: 60px 15px 0;
  text-align: center;

  @include mq.media(">=desktop") {
    display: flex;
    padding: 0;
    text-align: inherit;
  }
}

.SL_Sectionhead-content {
  @include mq.media(">=desktop") {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding-left: 48px;
    margin-right: 50px;
  }
}

.SL_Sectionhead-content-title {
  margin-bottom: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 29px;
  color: colors.$color-dark-gray;

  @include mq.media(">=desktop") {
    font-size: 3.4rem;
    line-height: 41px;
  }
}

.SL_Sectionhead-content-subtitle {
  display: block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
  color: colors.$color-red;
  
  @include mq.media(">=desktop") {
    font-size: 1.8rem;
    line-height: 22px;
  }
}

.SL_Sectionhead-content-text {
  margin-top: 24px;

  &:first-letter {
    text-transform: capitalize;
  }
}

.SL_Sectionhead-content-text > p {
  margin-bottom: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;

  @include mq.media(">=desktop") {
    font-size: 1.8rem;
    line-height: 25px;
  }
}

.SL_Sectionhead-content-buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

    
  @include mq.media("<desktop", ">=tablet") {
    padding: 0 20%;
  }

  @include mq.media(">=desktop") {
    flex-direction: row;
    align-items: flex-start;
  }
}

.SL_Sectionhead-content-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 16px;
  text-align: left;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 22px;

  @include mq.media(">=desktop") {
    width: 225px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: text-top;
  }
}

.SL_Sectionhead-content-button--devis {
  margin-bottom: 8px;
  color: colors.$color-white;
  background-color: colors.$color-red;
  border: 1px solid colors.$color-red;

  @include mq.media(">=desktop") {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.SL_Sectionhead-content-button--devis:hover {
  color: colors.$color-white;
}

.SL_Sectionhead-content-button--num {
  color: colors.$color-red;
  border: 1px solid colors.$color-red;
}

.SL_Sectionhead-content-button--num:hover {
  color: colors.$color-red;
}

.SL_Sectionhead-content-button--showroom {
  background-color: colors.$color-red;
  color: colors.$color-white;
}

.SL_Sectionhead-content-button--showroom:hover {
  color: colors.$color-white;
  background-color: colors.$color-red;
}

.SL_Card-img {
  display: none;

  @include mq.media(">=desktop") {
    display: block;
    width: 40%;
    min-height: 360px;
    max-height: 500px;
    object-fit: cover;
  }
}