@use 'utilities/mq';
@use 'utilities/colors';
@use 'utilities/grid';
// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
  * Media queries helper
  */
@include mq.media(">=tablet") {
  .mq--onlyPhone {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@include mq.media("<desktop") {
  .mq--onlyDesktop {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@include mq.media("<tablet") {
  .mq--onlyTablet {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
@include mq.media(">=desktop") {
  .mq--onlyTablet {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@include mq.media("<tablet") {
  .mq--noPhone {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@include mq.media(">=tablet", "<desktop") {
  .mq--noTablet {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@include mq.media(">=desktop") {
  .mq--noDesktop {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@media print {
  .mq--noPrint {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.SL_resultats {
  display: block;
  height: 74px;
  color: colors.$color-dark-gray;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 32px;
  
  @include mq.media("<desktop") {
    margin-bottom: 60px;
    text-align: center;
  }
}

.SL_resultats--territory {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @include mq.media("<desktop") {
    justify-content: center;
    order: 1;
    margin-bottom: 0;
  }
}

.SL_nosearch {
  display: block;

  margin-top: 150px;
  
  @include mq.media("<desktop") {
    margin-top: 120px;
  }
}

.SL_flex-container {
  display: flex;
  width: 100%;
}

.SL_flex-desktop {
  @include mq.media(">=desktop") {
    display: flex;
    align-items: flex-start;
  }
}

.SL_flex-desktop--territory {
  margin-top: 20px;

  @include mq.media("<desktop") {
    display: flex;
    flex-direction: column;
  }

  @include mq.media(">=desktop") {
    align-items: flex-end;
  }
}

.SL_mapContainer {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 24px;

  @include mq.media(">=desktop") {
    position: sticky;
    top: 8%;
    margin: 24px 0 96px 24px;
  }
}

@include mq.media(">=desktop") {

  .SL_ListCard--territory {
    max-height: 832px;
    width: 120%;
    margin: 24px 0;
    overflow-y: scroll;
    
    & > div:first-child {
      margin-top: 0;
    }

    & > div:last-child {
      margin-bottom: 0;
    }
    
    &::-webkit-scrollbar {
      width: 10px;
    }
    
    &::-webkit-scrollbar-button {
      height: 100px; //for vertical scrollbar
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: grey; 
      border-radius: 10px;
    }
  }
}

.SL_container-fixedRight {

  @media screen and (min-width: 992px) { 
    margin-right: 0;
    padding-right: 0;
    max-width: calc(960px + ((100vw - 960px)/2));
  }

  @media screen and (min-width: 1200px) { 
    margin-right: 0;
    padding-right: 0;
    max-width: calc(1210px + ((100vw - 1216px)/2));
  }
}