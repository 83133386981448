@use 'utilities/mq';
@use 'utilities/colors';

/*! SL_SL_Searchform component */ 
.SL_Searchform {
  position: relative;
  margin-top: 24px;
  
  @include mq.media(">=desktop") {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-top: 32px;
    margin-bottom: 48px;
  }
  
  .SL_Searchform-select {
    width: 100%;
    margin-bottom: 8px;
    padding: 20px 14px 20px 14px;
    color: colors.$color-dark-gray;
    background-color: colors.$color-gray-4;
    border: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 19px;
    
    
    @include mq.media(">=desktop") {
      width: 25%;
      height: 60px;
      margin-bottom: 0;
    }
    
    option {
      display: block;
      color: colors.$color-dark-gray;
      background-color: colors.$color-white;
    }
  }

  .SL_Searchform-placeholder {
    color: colors.$color-gray-2;
  }
  
  select:active {
    
    option {
      background-color: colors.$color-white;
    }
  }

  .SL_Searchform-autocompleteContainer {
    position: relative;
    width: 100%;
    height: 60px;
    margin-bottom: 24px;
    
    @include mq.media(">=desktop") {
      width: 35%;
      margin-bottom: 0;
    }
  }
  
  .SL_Searchform-autocomplete {
    width: 100%;
    padding: 20px 60px 20px 14px;
    color: colors.$color-dark-gray;
    background-color: colors.$color-gray-4;
    border: none;
    appearance: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 19px;

    @include mq.media(">=desktop") {
      height: 60px;
    }
  }
  
  
  .SL_Searchform-autocomplete::placeholder {
    color: colors.$color-gray-2;
  }
  
  .SL_Searchform-geoloc {
    position: absolute;
    right: 0;
    width: 60px;
    height: 60px;
    color: colors.$color-red;
    border-radius: 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 19px;
  }

  .SL_Searchform-geoloc:hover {
    -webkit-box-shadow: inset 0px 0px 10px 0px rgb(0 0 0 / 50%);
    box-shadow: inset 0px 0px 3px 0px rgb(0 0 0 / 50%);
  }
  
  .SL_Searchform-button {
    width: 100%;
    margin-bottom: 48px;
    padding: 19px 40px;
    border: 3px solid colors.$color-dark-gray;
    color: colors.$color-dark-gray;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 22px;
    
    @include mq.media(">=desktop") {
      width: 20%;
      max-width: 175px;
      margin-bottom: 0;
    }
  }
  
  .SL_Searchform-range {
    position: absolute;
    left: 50%;
    bottom: -115px;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
    
    @include mq.media(">=desktop") {
      left: 280px;
      bottom: -110px;
      width: 320px;
      transform: none;
    }
  }
  
  .SL_Searchform-range-title {
    color: colors.$color-dark-gray;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 19px;
  }
  
  .SL_Searchform-range-slider {
    -webkit-appearance: none;
    background: colors.$color-red;
    height: 4px;
    border-radius: 100px;
    width: 100%;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      background-color: colors.$color-white;
      border: 1px solid colors.$color-red;
      border-radius: 50%;
    }

    @include mq.media(">=desktop") {
      width: 300px;
    }  
  }

  .SL_Searchform-range-values {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    color: colors.$color-dark-gray;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 16px;
  }
}

.pac-container.pac-logo {
  border-radius: 0px 0px 4px 4px;

  .pac-item {
    position: relative;
    padding: 8px 24px 8px 24px;
    border: none;
  }

  .pac-item:not(:last-child)::after {
    content: '';
    position: absolute;
    background: colors.$color-border;
    height: 1px;
    width: 80%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
  
}

.pac-container.pac-logo::after {
  content: none;
}

.SL_Searchform-typeContainer-title {
  display: block;
  margin-bottom: 10px;
  color: colors.$color-dark-gray;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 19px;
}

.SL_Searchform-type-inputContainer {
  display: flex;
  margin-bottom: 24px;

  @include mq.media(">=desktop") {
    display: block;
    margin-bottom: 0;
  }  
}

.SL_Searchform-type-input {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: 19px;
  }
}

.SL_Searchform-type-input:first-child {
  margin-right: 24px;

  @include mq.media(">=desktop") {
    margin-bottom: 8px;
  }  
}

.SL_Searchform-type-inputContainer {

  .form-check__checkbox {
    margin-right: 8px;
    margin-top: 0;
  }

  .form-check__checkbox.checked {
    background-color: colors.$color-red;
    border: none;
  }
}